export function sumPrices(prices: number[]) {
  return (
    Math.round(prices.reduce((a: number, b: number) => a + b, 0) * 100) / 100
  );
}

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function round(value: number, precision: number) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export function assert<T>(value: T, message?: string): asserts value {
  if (value) {
    return;
  }

  throw new Error("Assertion failed" + (message ? `: ${message}` : ""));
}

export function getPrettyDate(date: Date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return month + "/" + day + "/" + year;
}

export function isInternalPath(path: string) {
  return path && path.startsWith("/") && !path.startsWith("//");
}
