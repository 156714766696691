import { useForm } from "react-hook-form";
import FeeBreakdown from "../../../components/FeeBreakdown/FeeBreakdown";
import { Medication } from "../../../types/medication";
import styles from "./styles.module.css";
import { PatientListItem } from "../../Patients2/types";
import { ApiStatus } from "../../../types/api_status";
import { useEffect, useState } from "react";
import { getPatients } from "../../Patients2/api";
import PrimaryButton from "../../../components/Button2/PrimaryButton";
import { useNavigate } from "react-router";
import PatientForm from "../PatientForm/PatientForm";

interface MedicationPopupProps {
  medication: Medication;
  onCancel: () => void;
}

interface FormInput {
  patientId: number;
}

export default function MedicationPopup(props: MedicationPopupProps) {
  const navigate = useNavigate();

  const [fetchPatientsState, setFetchPatientsState] = useState<{
    patients: PatientListItem[] | null;
    status: ApiStatus;
  }>({ patients: null, status: ApiStatus.LOADING });

  useEffect(() => {
    getPatients()
      .then((patients) => {
        setFetchPatientsState({
          patients: patients,
          status: ApiStatus.SUCCESS,
        });
      })
      .catch(() => {
        setFetchPatientsState((prev) => ({
          ...prev,
          status: ApiStatus.FAILURE,
        }));
      });
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<FormInput>();

  const [isAddingPatient, setIsAddingPatient] = useState(false);
  const selectedPatient = watch("patientId");

  if (+selectedPatient === -1 && !isAddingPatient) {
    setIsAddingPatient(true);
  }

  const onSubmit = (input: FormInput) => {
    navigate(
      `/patients/${input.patientId}/prescriptions/create?medicationId=${props.medication.pk}&back=/inventory`
    );
  };

  return (
    <div className={styles.medicationPopup}>
      {isAddingPatient ? (
        <PatientForm
          onDone={(patient) => {
            setIsAddingPatient(false);
            if (patient) {
              const patientItem = new PatientListItem(
                patient.id,
                patient.fullName,
                patient.phoneNumber,
                patient.email,
                patient.dateOfBirth,
                patient.displayAddress,
                patient.memberId,
                patient.groupId
              );
              let newPatients = [patientItem];
              if (fetchPatientsState.patients) {
                newPatients = [...newPatients, ...fetchPatientsState.patients];
              }
              setFetchPatientsState((prev) => ({
                ...prev,
                patients: newPatients,
              }));
              reset({ patientId: patient.id });
            } else {
              reset({ patientId: undefined });
            }
          }}
        ></PatientForm>
      ) : (
        <form className={styles.card} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.content}>
            <div className={styles.data}>
              <p className={styles.name}>
                {props.medication.displayName} {props.medication.strength}
              </p>
              <p className={styles.genericName}>
                {props.medication.genericName}
              </p>
            </div>
          </div>
          <FeeBreakdown
            condensed={true}
            title="Service Fees"
            tierName={props.medication.pricingTier.name}
            adminFee={props.medication.pricingTier.adminFee}
            sourcingFee={props.medication.pricingTier.sourcingFee}
            handlingFee={props.medication.pricingTier.handlingFee}
            quantity={props.medication.avgQtyPerScript}
            formulation={props.medication.formulation}
            className={styles.medicationFees}
            multiplier={1}
          ></FeeBreakdown>
          <div className={styles.inputs}>
            <div className={styles.inputContainer}>
              <label htmlFor="patientId">Recipient Patient</label>
              <select
                defaultValue={""}
                {...register("patientId", {
                  required: "Please select a patient",
                })}
                className={styles.select}
                id="patientId"
              >
                <option value="" disabled hidden>
                  Select patient
                </option>
                <option value={-1}>+ Add patient</option>
                {fetchPatientsState.patients &&
                  fetchPatientsState.patients.map((p) => (
                    <option value={p.id} key={p.id}>
                      {p.fullName}
                    </option>
                  ))}
              </select>
              {errors.patientId && (
                <p className={styles.error}>{errors.patientId.message}</p>
              )}
            </div>
            <div className={styles.actions}>
              <button
                className={styles.cancelButton}
                type="button"
                onClick={props.onCancel}
              >
                Cancel
              </button>
              <PrimaryButton type="submit">Start order</PrimaryButton>
            </div>
          </div>
        </form>
      )}
    </div>
  );
}
